import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import NextLink from '../../navigation/NextLink';
import RespImg from '../../ui/RespImg';

import instagram from '../../../assets/icons/instagram.svg';
import youtube from '../../../assets/icons/youtube.svg';
import facebook from '../../../assets/icons/facebook.svg';

const Social = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: -3px;
  border-right: 1px solid white;
  overflow: hidden;
  max-height: 278px;
  background-color: ${theme.palette.lightGrey};
  &:after {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-repeat: no-repeat !important;
  }
  &.instagram:after {
    background: url(${instagram});
  }
  &.youtube:after {
    background: url(${youtube});
  }
  &.facebook:after {
    background: url(${facebook});
  }
  &.full-width {
    width: 20%;
    padding-bottom: 20%;
    ${theme.breakpoints.down('sm')} {
      width: 33.33%;
      padding-bottom: 33.33%;
    }
  }
  &.full-width:nth-child(5),
  &.full-width:last-child {
    border-right: none !important;
  }
  &.half-width {
    width: 33.33%;
    padding-bottom: 33.33%;
  }
  &.quarter-width {
    width: 50%;
    padding-bottom: 50%;
  }

  picture img {
    object-fit: cover;
    height: 18vh;
    width: 100%;
  }
`

// picture,
// img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   transition: all 200ms ease-in-out;
//   &:hover {
//     transform: scale(1.15, 1.15);
//   }
// }

export const SocialThumbnail = props => {
  const {socialClass, link, image, classWidth} = props;

  return (
    <Social className={socialClass + ' ' + classWidth}>
      <NextLink href={link} prefetch={false}>
        <a target={"_blank"}>
          <div
            style={{ 
              backgroundImage: `url(${image.sizes.default.jpg})`,
              height: '100%',
              position: 'absolute',
              width: '100%',
              backgroundSize: 'cover'
            }}
          />
          {/* <RespImg {...image}/> */}
        </a>
      </NextLink>
    </Social>
  );
}

SocialThumbnail.propTypes = {
  socialClass: PropTypes.string,
  classWidth: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object
}

SocialThumbnail.defaultProps = {
  socialClass: "instagram",
  classWidth: "full-width"
}

export default SocialThumbnail;