import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import SocialThumbnail from '../SocialThumbnail'

const SocialsContainer = styled.div`
  
`

export const SocialGallery = props => {
  const {data, classWidth, slice} = props;
  return (
    <>
      {data &&
        <SocialsContainer>
          {data.items && data.items.slice(0, slice).map(function(social, index) {
            return (
              <SocialThumbnail key={index} {...social} classWidth={classWidth} />
            )
          })}
        </SocialsContainer>
      }
    </>
  );
}

SocialGallery.propTypes = {
  hashtag: PropTypes.string,
  data: PropTypes.object,
  slice: PropTypes.number
}

SocialGallery.defaultProps = {
  slice: 10
}

export default SocialGallery;