import React, {useEffect, useState} from 'react';
import {gql} from "apollo-boost";
import client from '../src/graphql/client';
import {get} from 'lodash';
import styled from 'styled-components';

import respImgQuery from '../src/graphql/queries/resp-img';
import refactorRespImg from '../src/data-restructure/ui/resp-img';
import refactorContentBuilderData from '../src/data-restructure/contentBuilder';
import ContentBuilder from '../src/containers/ContentBuilder';
import contentBuilderQuery from '../src/graphql/queries/content-builder';
import Social from '../src/containers/Social';
import HomeSocialGrid from '../src/components/multimedia/HomeSocialGrid';
import seoQuery from '../src/graphql/queries/seo';
import {hbItemQuery} from '../src/graphql/queries/content-builder/hero-banner-carousel';
import refactorHbCarousel from '../src/data-restructure/multimedia/hero-banner-carousel';
import { theme } from '../src/themes/default-theme';

const Home = (props) => {

  // const [key, setKey] = useState(Date.now());

  const {
    cbData,
    dealerHb
  } = props

  return (
    <>
      {cbData &&
        <ContentBuilder
          data={cbData}
        />
      }

      {!cbData && process.env.DEALER_SITE &&
        <ContentBuilder
          dealerCb={true}
          topOfDealerPage={true}
          dealerHb={dealerHb}
        />
      }

      <HomeSocialGrid
          countryCode={props.countryProps.countryCode}
      />

    </>
  );
}

export async function getStaticProps(context) {

  const countryCode = process.env.DEV_COUNTRY_CODE ? process.env.DEV_COUNTRY_CODE.toLowerCase() : process.env.COUNTRY_CODE.toLowerCase();
  const domain = process.env.DEV_ASSIGNED_DOMAIN ? process.env.DEV_ASSIGNED_DOMAIN.toLowerCase() : process.env.ASSIGNED_DOMAIN.toLowerCase();

  const GET_HOMEPAGE_CONTENT_BUILDER_QUERY = gql`query GET_HOMEPAGE {
    pageBy(uri: "home-page?${countryCode}") {
      title
      seo {
        ${seoQuery}
      }
      acf_domains {
        assignedDomains
      }
      ${contentBuilderQuery()}
    }
  }
  `

  const GET_ARTICLES = gql`
    query GET_ARTICLE {
      articles(first: 100) {
        nodes {
          uri
          title
          date
          acf_domains {
            assignedDomains
          }
          articleCategories {
            nodes {
              name
            }
          }
          acf_article {
            artFeaturedArticle
            artDatePosted
            artTeaserCopy
            artThumbnailImage {
              ${respImgQuery}
            }
          }
        }
      }
    }
  `

  const GET_DEALER_HB = gql`
    query GET_DEALER_BANNER {
      dealerSiteHeroBanners {
        nodes {
          title
          acf_dealer_site_banners {
            fieldGroupName
            ${hbItemQuery('dshb')}
          }
        }
      }
    }
  `

  var cbResult;
  try {
    cbResult = await client.query({
      query: GET_HOMEPAGE_CONTENT_BUILDER_QUERY
    });
  } catch {
    cbResult = {
      error: true
    }
  }

  if (cbResult.error) {
    return {
      props: {}
    }
  }

  const articleProps = await client.query({
    query: GET_ARTICLES
  });

  const dealerHbData = process.env.DEALER_SITE ? await client.query({
    query: GET_DEALER_HB
  }) : null;

  const dealerHbObj = get(dealerHbData, 'data.dealerSiteHeroBanners.nodes[0].acf_dealer_site_banners.dshbItemsRep', null);
  const dealerHb = dealerHbObj ? refactorHbCarousel(dealerHbObj, {}, 'dshb') : null

  // seo
  const title = get(cbResult, 'data.pageBy.title', null);
  const seo = get(cbResult, 'data.pageBy.seo', null);
  const assignedDomains = get(cbResult, 'data.pageBy.acf_domains.assignedDomains', []); 

  const meta = {
    title,
    seo,
    assignedDomains,
  };

  const contentBuilderData = cbResult ? get(cbResult, 'data.pageBy', null) : null;
  const cbData = contentBuilderData ? await refactorContentBuilderData(contentBuilderData) : null;
  const articleData = get(articleProps, 'data.articles.nodes', null);

  const articles = articleData ? articleData.filter(item => {
    const assignedDomains = get(item, 'acf_domains.assignedDomains', []);
    return assignedDomains.indexOf(domain) !== -1
  }).map(item => {
    return {
      ...item,
      uri: item.uri.replace('-ccnz', '').replace('-ccau', '')
    }
  }) : null

  const filteredArticles = articles ? articles.filter(article => article.articleCategories && article.articleCategories.nodes.length > 0) : [];

  const articleConversion = filteredArticles.map(item => {
    const title = item.title;
    const teaser = item.acf_article.artTeaserCopy;
    const uri = item.uri;
    const featured = item.acf_article.artFeaturedArticle;
    const category = item.articleCategories.nodes[0].name;
    const img = item.acf_article.artThumbnailImage ? refactorRespImg(item.acf_article.artThumbnailImage, [{
      size: 'default',
      key: 'image_size_466x314'
    }]) : null;

    return {
      title,
      teaser,
      uri,
      featured,
      category,
      img
    }
  })

  return { 
    props: { 
      cbData: process.env.DEALER_SITE ? null : cbData,
      articleConversion,
      domain,
      meta,
      dealerHb
    }
  }
};

export default Home;
